<template>
  <div class="flex">
    <!-- <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" /> -->
    <div class="absolute top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="px-4 md:px-10 mx-auto w-full relative -mt-11">
      <HelpBar @getData="getData" />
      <div class="w-full lg:w-full text-gray-600 px-4">
        <SlideUp>
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-white border-0 shadow-xl"
            ref="invoice"
          >
            <div class="px-14 py-8 overflow-auto" style="min-height: 800px">
              <div
                v-if="editMode == false"
                class="cursor-pointer flex justify-end"
                @click="editMode = !editMode"
              >
                <span
                  class="px-2 py-1 bg-rose-500 hover:bg-rose-600 text-green-50 rounded capitalize"
                >
                  {{ $t("edit") }}
                </span>
              </div>
              <div
                v-else
                class="cursor-pointer flex justify-end"
                @click="handleSave"
              >
                <span
                  class="px-2 py-1 bg-green-500 hover:bg-green-600 text-green-50 rounded capitalize"
                >
                  {{ $t("save") }}
                </span>
              </div>
              <div class="flex justify-between">
                <div>
                  <img
                    v-if="company.logo && company.logo.path"
                    width="150"
                    :src="company.logo.path"
                  />
                  <div class="my-4">
                    <p class="font-bold uppercase">{{ $t("from") }}:</p>
                    <div class="ml-2">
                      <p class="font-semibold">
                        {{ company.name }}
                      </p>
                      <p>
                        {{ me.name }}
                      </p>
                      <p>
                        {{ me.email }}
                      </p>
                      <p>
                        {{ me.company.address.street }}
                        {{ me.company.address.number }}
                      </p>
                      <p>
                        {{ me.company.address.postal_code }}
                        {{ me.company.address.city }}
                      </p>
                      <p>
                        {{ me.company.address.state }}
                        {{ me.company.address.country }}
                      </p>
                    </div>
                  </div>

                  <div>
                    <p class="font-bold uppercase">
                      {{ $t("to") }}:
                      <span
                        v-if="editMode == true"
                        @click="clientDialog = true"
                        class="text-rose-500 text-xs cursor-pointer"
                      >
                        ({{ $t("edit") }})
                      </span>
                    </p>
                    <div v-if="selectedSupplier != null" class="ml-2">
                      <p class="font-semibold">
                        {{ selectedSupplier.name }}
                      </p>
                      <!-- <p>
                        {{ getAddress(selectedSupplier.address) }}
                      </p> -->
                      <p>
                        {{ selectedSupplier.address.street }}
                        {{ selectedSupplier.address.number }}
                      </p>
                      <p>
                        {{ selectedSupplier.address.postal_code }}
                        {{ selectedSupplier.address.city }}
                      </p>
                      <p>
                        {{ selectedSupplier.address.state }}
                        {{ selectedSupplier.address.country }}
                      </p>
                    </div>
                    <div class="ml-2" @click="clientDialog = true" v-else>
                      {{ $t("select a supplier") }}
                    </div>
                  </div>
                </div>
                <div class="mt-4 text-right">
                  <h1 class="text-3xl font-bold uppercase text-gray-600">
                    {{ $t("invoice") }}
                  </h1>
                  <p
                    :class="{
                      'flex justify-between items-center mr-2 my-2': editMode
                    }"
                  >
                    <span class="font-semibold text-gray-500 mr-1">
                      {{ $t("number") }}:
                    </span>
                    <span v-if="!editMode">
                      {{ invoiceNumber }}
                    </span>
                    <input
                      v-if="editMode"
                      style="min-width: 220px"
                      class="border px-4 py-2 rounded"
                      v-model="invoiceNumber"
                    />
                  </p>
                  <p
                    :class="{
                      'flex justify-between items-center mr-2 my-2': editMode
                    }"
                  >
                    <span class="font-semibold text-gray-500 mr-1">
                      {{ $t("date") }}:
                    </span>
                    <span v-if="!editMode">
                      {{ getDateOnly(dateSent) }}
                    </span>
                    <el-date-picker
                      v-if="editMode"
                      v-model="dateSent"
                      type="date"
                      placeholder="Pick a day"
                    />
                  </p>
                  <p
                    :class="{
                      'flex justify-between items-center mr-2 my-2': editMode
                    }"
                  >
                    <span class="font-semibold text-gray-500 mr-1">
                      {{ $t("due date") }}:
                    </span>
                    <span v-if="!editMode">
                      {{ getDateOnly(dueDate) }}
                    </span>
                    <el-date-picker
                      class="w-2/3"
                      v-if="editMode"
                      v-model="dueDate"
                      type="date"
                      placeholder="Pick a day"
                    />
                  </p>
                  <div class="flex mt-5" v-if="editMode">
                    <div
                      @click="status = 'paid'"
                      class="mx-2 px-3 py-1 rounded cursor-pointer"
                      :class="{
                        'bg-green-500 text-green-50': status == 'paid',
                        border: status != 'paid'
                      }"
                    >
                      {{ $t("paid") }}
                    </div>
                    <div
                      @click="status = 'unpaid'"
                      class="mx-2 px-3 py-1 rounded cursor-pointer"
                      :class="{
                        'bg-rose-500 text-rose-50': status == 'unpaid',
                        border: status != 'unpaid'
                      }"
                    >
                      {{ $t("unpaid") }}
                    </div>
                    <div
                      @click="status = 'rejected'"
                      class="mx-2 px-3 py-1 rounded cursor-pointer"
                      :class="{
                        'bg-blue-500 text-blue-50': status == 'rejected',
                        border: status != 'rejected'
                      }"
                    >
                      {{ $t("rejected") }}
                    </div>
                  </div>
                  <div
                    class="mt-5 text-center px-3 py-1 uppercase font-semibold"
                    v-if="!editMode"
                    :class="{
                      'bg-green-500 text-green-50': status == 'paid',
                      'bg-rose-500 text-rose-50': status == 'unpaid',
                      'bg-blue-500 text-blue-50': status == 'rejected'
                    }"
                  >
                    {{ status }}
                  </div>
                </div>
              </div>
              <div class="border-t mt-10">
                <div class="mt-10 mb-3">
                  <span class="font-bold uppercase">
                    {{ $t("items") }}
                  </span>
                  <span
                    v-if="editMode"
                    @click="itemsDialog = true"
                    class="text-rose-500 text-xs cursor-pointer"
                  >
                    ({{ $t("add item") }})
                  </span>
                </div>
                <div>
                  <div
                    class="flex justify-between px-4 py-1 font-semibold bg-coolGray-50"
                  >
                    <div>{{ $t("description") }}</div>
                    <div style="min-width: 200px">{{ $t("amount") }}</div>
                  </div>

                  <div
                    v-for="(line, index) in selectedItems"
                    :key="index + line.id + 'selectedline'"
                    class="px-4 py-2 flex justify-between items-center border-t"
                    :class="{
                      'bg-gray-50': index % 2 != 0
                    }"
                  >
                    <div>
                      <span class="text-gray-700 font-semibold">
                        {{ line.checkout.bike.name }}
                      </span>
                      <!-- {{ line.checkout.bike.name }} -->
                      <div class="text-xs text-gray-500">
                        {{ formatTime(line.grabbed_at) }}
                        {{ formatTime(line.returned_at) }}
                      </div>
                    </div>
                    <div v-if="editMode == true">
                      <input
                        v-model.number="amounts[line.checkout.id]"
                        class="border px-4 py-2 rounded"
                        type="number"
                      />
                      <el-popconfirm
                        :title="$t('Are you sure to delete this?')"
                        @confirm="removeInvoiceLine(line)"
                      >
                        <i
                          slot="reference"
                          class="far fa-trash-alt text-rose-500 ml-2"
                        />
                      </el-popconfirm>
                    </div>
                    <div class="w-48" v-if="editMode == false">
                      € {{ amounts[line.checkout.id] }}
                    </div>
                  </div>
                </div>
                <div
                  class="border-t flex justify-end my-3 py-5 bg-rose-50 px-5"
                >
                  <h3 class="font-semibold capitalize">
                    {{ $t("total amount") }}
                  </h3>
                  <div class="text-right" style="min-width: 150px">
                    € {{ total }}
                  </div>
                </div>
              </div>
              <div class="border-t mt-10" v-if="editMode != true">
                <div class="mt-10 font-semibold">
                  {{ $t("send payments") }} - email,
                  <!-- mollie, -->
                  <span
                    class="cursor-pointer text-teal-700 border px-2 py-1 rounded border-teal-700"
                    @click="downloadPDF"
                  >
                    {{ $t("download pdf") }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </SlideUp>
      </div>
    </div>
    <el-dialog
      :title="$t('select items')"
      :visible.sync="itemsDialog"
      width="350px"
    >
      <div class="overflow-auto" style="height: calc(100vh - 500px)">
        <div
          v-for="(checkout, index) in checkouts"
          :key="checkout.id"
          class="px-2 py-1 cursor-pointer"
          :class="{
            'bg-gray-100': index % 2 == 0
          }"
          @click="toggleSelectedItem(checkout)"
        >
          <i
            v-if="selectedItems[checkout.id] != undefined"
            class="fas fa-check-square"
          />
          <i v-else class="fas fa-square" />
          <span class="font-semibold">
            {{ checkout.bike.name }}
          </span>
          {{ formatTime(checkout.grabbed_at) }}
          {{ formatTime(checkout.returned_at) }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          class="text-rose-500 border border-rose-500 px-3 py-1 mx-2 rounded"
          @click="itemsDialog = false"
        >
          {{ $t("Cancel") }}
        </button>
        <button
          class="text-blue-50 bg-blue-500 px-3 py-1 mx-2 rounded"
          type="primary"
          @click="itemsDialog = false"
        >
          {{ $t("Confirm") }}
        </button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('select supplier')"
      :visible.sync="clientDialog"
      width="350px"
    >
      <div>
        <el-select
          clear="w-full"
          @change="changeSelectedSupplier"
          v-model="selectedSupplierId"
          placeholder="Select"
        >
          <el-option
            v-for="supplier in suppliers"
            :key="supplier.id"
            :label="supplier.name"
            :value="supplier.id"
          />
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          class="text-rose-500 border border-rose-500 px-3 py-1 mx-2 rounded"
          @click="clientDialog = false"
        >
          {{ $t("Cancel") }}
        </button>
        <button
          class="text-blue-50 bg-blue-500 px-3 py-1 mx-2 rounded"
          type="primary"
          @click="clientDialog = false"
        >
          {{ $t("Confirm") }}
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import formatting from "../mixins/formatting";
import HelpBar from "../components/HelpBar.vue";
import SlideUp from "../components/transitions/SlideUp.vue";
export default {
  name: "Invoice",
  mixins: [formatting],
  components: { SlideUp, HelpBar },
  data() {
    let editMode = this.$route.params.id == "new" ? true : false;
    let today = new Date();
    return {
      editMode,
      selectedSupplierId: null,
      selectedSupplier: null,
      clientDialog: false,
      itemsDialog: false,
      invoiceNumber: "",
      dateSent: null,
      dueDate: null,
      today,
      selectedItems: {},
      status: "unpaid",
      amounts: {}
    };
  },
  computed: {
    me() {
      return this.$store.state.accounts?.user;
    },
    company() {
      return this?.me?.company;
    },
    invoice() {
      return this.$store.state.invoices.invoice;
    },
    suppliers() {
      return this.$store.state.suppliers.suppliers;
    },
    supplier() {
      return this.$store.state.suppliers.suppliers;
    },
    total() {
      return Object.values(this.amounts).reduce((accu, item) => {
        let itemVal = !isNaN(parseInt(item)) ? parseInt(item) : 0;
        accu = parseInt(accu) + itemVal;
        return accu;
      }, 0);
    },
    checkouts() {
      return this.$store.state.checkouts.checkouts.filter(
        checkout => !Object.keys(this.amounts).includes(checkout.id)
      );
    }
  },
  methods: {
    removeInvoiceLine(line) {
      if (line.id) {
        this.$store.dispatch("invoices/deleteInvoiceLine", {
          invoiceId: this.$route.params.id,
          lineId: line.id,
          cb: () => this.getInvoice()
        });
      }
    },
    handleSave() {
      let invoice = {
        send_to_supplier_id: this.selectedSupplierId,
        number: this.invoiceNumber,
        date: this.dateSent,
        due_date: this.dueDate,
        invoice_lines: Object.keys(this.selectedItems).map(item => {
          let line = {
            checkout_id: parseInt(item),
            amount: this.amounts[item]
          };
          if (this.selectedItems[item]?.id) {
            line.id = parseInt(this.selectedItems[item]?.id);
          }
          return line;
        }),
        status: this.status
        // amounts: {}
      };
      if (this.$route.params.id == "new") {
        this.$store.dispatch("invoices/createInvoice", {
          invoice,
          cb: data =>
            this.$router.push({ name: "Invoice", params: { id: data.id } })
        });
      } else {
        this.$store.dispatch("invoices/updateInvoice", {
          id: this.$route.params.id,
          invoice
        });
      }
      this.editMode = !this.editMode;
    },
    toggleSelectedItem(checkout) {
      // let res;
      if (checkout.id in this.selectedItems) {
        this.$delete(this.selectedItems, checkout.id);
      } else {
        // res = checkout;
        this.$set(this.selectedItems, checkout.id, {
          checkout,
          checkout_id: checkout.id,
          grabbed_at: checkout.grabbed_at,
          returned_at: checkout.returned_at
        });
        this.$set(this.amounts, checkout.id, 0);
      }
      // this.$set(this.selectedItems, checkout.id, checkout);
    },
    bindData(invoice) {
      this.selectedSupplierId = invoice.send_to_supplier_id;
      this.selectedSupplier = invoice.send_to_supplier;
      this.invoiceNumber = invoice.number;
      this.dateSent = invoice.date;
      this.dueDate = invoice.due_date;
      this.status = invoice.status;
      // this.amounts = {};
      this.selectedItems = invoice.invoice_lines.reduce((accu, item) => {
        accu[item.checkout.id] = {
          // lineId: item.id,
          id: item.id,
          checkout_id: item.checkout.id,
          checkout: item.checkout,
          grabbed_at: item.checkout.grabbed_at,
          returned_at: item.checkout.returned_at
        };
        return accu;
      }, {});
      this.amounts = invoice.invoice_lines.reduce((accu, item) => {
        accu[item.checkout.id] = item.amount;
        return accu;
      }, {});
    },
    getData() {
      this.$store.dispatch("checkouts/getAllCheckouts");
    },
    getInvoice() {
      this.$store.dispatch("invoices/getInvoice", {
        id: this.$route.params.id,
        cb: this.bindData
      });
    },
    downloadPDF() {
      this.$store.dispatch("invoices/getPDFInvoice", {
        id: this.$route.params.id
      });
    },
    changeSelectedSupplier() {
      this.selectedSupplier = this.suppliers.find(
        ({ id }) => id == this.selectedSupplierId
      );
    }
  },
  created() {
    this.getData();
    if (this.$route.params.id != "new") {
      this.getInvoice();
    }
    this.$store.dispatch("hubs/getAllHubs", hubs => {
      this.$store.commit(
        "SET_HUBS",
        hubs.map(({ id }) => id)
      );
    });
  }
};
</script>
